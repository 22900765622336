<template>

  <div class="error-404-container">

    <div class="error-404-image-text">
      <div class="text404">Интересуетесь продуктами Tion.ru?<br/>
        Здесь можно узнать все, что хотите:
        <a href="/">www.tion.ru</a>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import {
  defaultPage404Heads,
  pageHeadersStateSymbol,
  PageHeadsStore,
  prepareHead
} from "../states/page-heads";
import {inject} from "vue";

export default {
  name: 'Page404',
  setup() {
    const head: PageHeadsStore | undefined = inject(pageHeadersStateSymbol)
    if (head) {
      head.updateHead(prepareHead([defaultPage404Heads]))
    }
  }
}
</script>

<style lang="scss" scoped>
.error-404-container {

  width: 100%;
  height: 595px;
  display: flex;
  flex-direction: column-reverse;

  background-image: url('/src/assets/pages/404/404-28-08.jpg?webp');
  background-repeat: no-repeat;
  background-position: top center;
  background-attachment: scroll;

  @media (max-width: 767px) {
    background-size: 700px;
    height: 386px;
  }

  .error-404-image-text,
  .error-404-search {
    text-align: center;

    @media (max-width: 767px) {
    }
  }
}

.text404 {
  text-transform: none;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 40px;

  a {
    font-size: 27px;
    color: #ffffff;
    text-decoration: underline;
  }

  @media (max-width: 767px) {
  }
}
</style>
